import React from 'react';
import {
  ArrayParam,
  BooleanParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import Layout from '../../modules/layout';
import Search from '../../components/ui/filterSearch';
import ResultsList from '../../components/ui/resultsList';
import { useProfileFilters } from '../../hooks/useProfileFilters';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useLocale } from '../../context/locale.context';

const ProfileOverview = () => {
  const { locale } = useLocale();
  const [query, _] = useQueryParams({
    profileTypeId: ArrayParam,
    regionId: ArrayParam,
    countryId: ArrayParam,
    fieldOfWorkId: ArrayParam,
    sectorId: ArrayParam,
    yearId: ArrayParam,
    sort: StringParam,
    find: StringParam,
    inspiration: BooleanParam,
  });

  const { allContentfulSearchChip, allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulSearchChip(
          filter: {
            content_search: {
              elemMatch: {
                page: { elemMatch: { slug: { eq: "brands-creatives" } } }
              }
            }
          }
        ) {
          nodes {
            behaviour
            queryParameters
            tag
            label
            node_locale
          }
        }
        allContentfulMicrocopy(
          filter: { key: { eq: "placeholder.whatDoYouWantToExplore" } }
        ) {
          nodes {
            value
            node_locale
          }
        }
      }
    `
  );

  const localizedChips = {
    nodes: allContentfulSearchChip.nodes.filter(
      (chip) =>
        chip.node_locale === locale && chip.behaviour !== 'Query Parameter'
    ),
  };

  const onSubmit = (searchTerm) => {
    navigate(searchTerm, {
      replace: true,
    });
  };

  const resetSearchTerm = () => navigate('?', { replace: true });

  const onInspire = () => {
    navigate('?inspiration=1', { replace: true });
  };

  const queryFilters = {
    profileTypes: query.profileTypeId ? query.profileTypeId.map(Number) : [],
    fieldsOfWork: query.fieldOfWorkId ? query.fieldOfWorkId.map(Number) : [],
    sectors: query.sectorId ? query.sectorId.map(Number) : [],
    region: query.regionId ? query.regionId.map(String) : [],
    countries: query.countryId ? query.countryId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : [],
    inspiration: query.inspiration,
    sort: query.sort,
    find: query.find,
  };

  return (
    <Layout
      title={'title.profileOverview'}
      description={'desc.profileOverview'}
      slug={'brands-creatives/overview'}
    >
      <Search
        onSubmit={onSubmit}
        onInspire={onInspire}
        resetSearchTerm={resetSearchTerm}
        initSearchTerm={query.find}
        chips={localizedChips.nodes}
        type="Companies"
        placeholder={
          allContentfulMicrocopy.nodes.find(
            (copy) => copy.node_locale === locale
          ).value
        }
      />
      <ResultsList
        queryFilters={queryFilters}
        searchTerm={query.find}
        resetSearchTerm={resetSearchTerm}
        filtersHook={useProfileFilters}
        countLabelKey="label.countProfiles"
        noResultsLabelKey="results.profiles.noResults"
        teaserType="company"
        hideSort
        type="profile"
      />
    </Layout>
  );
};

export default ProfileOverview;
