import { useEffect, useState } from 'react';
import { getAPIRoot } from '../helpers/fetch';
import {
  getYearFilters,
  getMappedFilterDates,
  getOpenedFilters,
} from '../helpers/filters';
import { API_ROUTES } from '../utils/constants';

const COMPANIES_ID = 1;
const DESIGN_STUDIOS_ID = 2;
const DESIGN_ORGANIZATIONS_ID = 7;
const MUSEUMS_ID = 8;

// adapt current filters to what backend expects
const prepareFiltersForAPI = (filters, apiFilters) => {
  const filtersForAPI = {
    types: filters?.profileTypes || [],
    // if there are regions selected but no countries, grab all countries from those regions
    countries:
      (filters?.region?.length && filters?.countries?.length === 0
        ? apiFilters?.countries
            .filter((country) => filters?.region?.includes(country.region))
            .map((country) => country.id)
        : filters?.countries) || [],
    // send an empty array for years-filter to get all profiles (with year == int || null)
    years: filters?.time?.includes(4)
      ? []
      : Array.from(
          new Set(
            filters?.time?.map((year) => getMappedFilterDates()[year]).flat()
          )
        ),
    find: filters?.find || '',
    businessSectors: filters?.sectors || [],
    fieldsOfWork: filters?.fieldsOfWork || [],
  };

  return JSON.stringify(filtersForAPI);
};

export const useProfileFilters = (filters) => {
  const [totalCount, setTotalCount] = useState(null);
  const [apiFilters, setApiFilters] = useState(null);
  const [visibleFilters, setVisibleFilters] = useState(null);
  const [page, setPage] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noMoreResults, setNoMoreResults] = useState(true);
  const [payload, setPayload] = useState('');
  const [windowLocation, setWindowLocation] = useState(null);

  useEffect(() => {
    setWindowLocation(window.location);
  }, []);

  // get filters from backend when component loads
  useEffect(() => {
    getAPIFilters();
  }, []);

  // initialize visible filters after receiving possible filters from backend
  // change them if filters changed
  useEffect(() => {
    if (apiFilters) {
      setVisibleFilters({
        ...apiFilters,
        fieldsOfWork: getFieldsOfWork(),
        sectors: getSectors(),
        countries: getCountries(),
      });
    }
  }, [apiFilters, filters]);

  // update results once we have calculated what filters should be visible
  useEffect(() => {
    if (visibleFilters && filters) {
      updateResults();
    }
  }, [visibleFilters]);

  // update results on page change
  useEffect(() => {
    if (page > 0) {
      updateResults();
    }
  }, [page]);

  const increasePage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // returns fields of work filters that should be visible
  const getFieldsOfWork = () => {
    // If company, design organizations or museum  is selected, hide fields of Work
    if (
      filters?.profileTypes?.some((id) =>
        [COMPANIES_ID, DESIGN_ORGANIZATIONS_ID, MUSEUMS_ID].includes(id)
      )
    ) {
      return [];
    }

    return apiFilters?.fieldsOfWork;
  };

  // returns sector filters that should be visible
  const getSectors = () => {
    // If design organization, museum or architecture studios is selected, hide sectors
    if (
      filters?.profileTypes?.some((id) =>
        [DESIGN_STUDIOS_ID, DESIGN_ORGANIZATIONS_ID, MUSEUMS_ID].includes(id)
      )
    ) {
      return [];
    }

    return apiFilters?.sectors;
  };

  // returns country filters that should be visible
  const getCountries = () => {
    // if more than 1 region selected, remove countries
    if (filters?.region?.length > 1) {
      return [];
    }
    // if 1 region selected, only show countries from that region
    if (filters?.region?.length === 1) {
      const regionName = apiFilters?.region?.find(
        (region) => region.id === filters?.region[0]
      ).id;
      return apiFilters?.countries?.filter(
        (country) => country.region === regionName
      );
    }
    // if no regions selected, show all countries
    if (!filters?.region?.length) {
      return apiFilters?.countries;
    }
  };

  useEffect(() => {
    sessionStorage?.setItem(
      'profileQueryParams',
      new URLSearchParams(windowLocation?.search).toString()
    );
    sessionStorage?.removeItem('returnToSearchPage');
  }, [windowLocation?.search]);

  useEffect(() => {
    sessionStorage?.setItem('profilePayload', payload);
  }, [payload]);

  useEffect(() => {
    sessionStorage?.setItem('profileCount', totalCount);
  }, [totalCount]);

  const getInspirationResults = async () => {
    let apiRoot = await getAPIRoot();
    const url = `${apiRoot}${API_ROUTES.COMPANIES_RANDOM}/15`;
    const options = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(url, options);
    const json = await response.json();

    return json['items'];
  };

  const getFiltersThatShouldApply = () => {
    // get only filters that are visible
    return Object.fromEntries(
      Object.entries(filters).map(([key, appliedFilter]) => {
        if (Array.isArray(appliedFilter)) {
          return [
            key,
            appliedFilter.filter((appliedFilterItem) =>
              visibleFilters[key].find(
                (visibleFilter) => visibleFilter.id === appliedFilterItem
              )
            ),
          ];
        } else {
          return [key, appliedFilter];
        }
      })
    );
  };

  const getFilteredResultsJsonResponse = async () => {
    let apiRoot = await getAPIRoot();

    const filtersThatShouldApply = getFiltersThatShouldApply();

    const _payload = prepareFiltersForAPI(filtersThatShouldApply, apiFilters);
    setPayload(_payload);
    const url = `${apiRoot}${API_ROUTES.COMPANIES}/${page}`;
    const options = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json',
      },
      body: _payload,
    };

    const response = await fetch(url, options);
    return response.json();
  };

  // get filters from backend, rename them and set state
  const getAPIFilters = async () => {
    let apiRoot = await getAPIRoot();
    const url = apiRoot + API_ROUTES.COMPANIES_FILTERS;

    const response = await fetch(url);
    const _apiFilters = await response.json();

    setApiFilters({
      profileTypes: _apiFilters['types'],
      region: _apiFilters['regions'],
      countries: _apiFilters['countries'],
      time: getYearFilters({
        allTime: true,
        showCurrentYear: false,
        type: 'profile',
      }),
      fieldsOfWork: _apiFilters['fieldsOfWork'],
      sectors: _apiFilters['businessSectors'],
    });
  };

  const updateResults = async () => {
    setLoading(true);

    if (filters?.inspiration) {
      setResults(await getInspirationResults());
      setNoMoreResults(true);
    }

    const json = await getFilteredResultsJsonResponse();

    setTotalCount(json.count);

    if (!json.items.length) {
      setNoMoreResults(true);
    }

    if (page > 0) {
      const _results = results.concat(json.items);
      if (_results.length === json.count) {
        setNoMoreResults(true);
      }
      setResults(_results);
    } else {
      setResults(json.items);
      setNoMoreResults(json.items.length === json.count);
    }

    setLoading(false);
  };

  return {
    filterOrderByKey: [
      'profileTypes',
      'time',
      'region',
      'countries',
      'fieldsOfWork',
      'sectors',
    ],
    visibleFilters,
    openedFilters: getOpenedFilters(filters, ['profileTypes', 'time']),
    increasePage,
    results,
    loading,
    noMoreResults,
    totalCount,
  };
};
